/* eslint-disable react/jsx-pascal-case */
import React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './App';
import Header from './Components/Layout/Header';
import Header_new from './Components/Layout/Header_new';
import Footer from './Components/Layout/Footer';
import ReactModal from 'react-modal';

ReactModal.setAppElement('#root');
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <React.StrictMode>
    {/*<Header />*/}
    <Header_new />
    <App />
    <Footer />
  </React.StrictMode>,
);