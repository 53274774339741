import React from 'react';
import "../../../static/css/notfound.css"
const NotFound = () => {
    return (
        <>
            <div id="notfound">
                <div class="notfound">
                    <div class="notfound-404">
                        <h1>404</h1>
                    </div>
                    <h2>해당 페이지를 찾을 수 없습니다.</h2>
                    <p>찾고 있는 페이지가 이름이 변경되었거나 일시적으로 사용할 수 없는 페이지 입니다.<br /> <a href="/">메인 페이지로 돌아가기</a></p>
                </div>
            </div>
        </>
    );
};

export default NotFound;