import Navbar from './Navbar'
import Logo from '../../static/img/logo.png'
import '../../static/css/header.css'

function Header_new(props) {
  return (
    <header id='topbox'>
      <div className='nav-area'>
        <a href='/' className='logo'>
          <img
          src={Logo}
           width="200"
           height="67"
           className="d-inline-block align-top"
           alt="Vikcloud + Juhosystems Log"
          />
        </a>
        <Navbar/>
      </div>
    </header>
  );
}

export default Header_new;