import { useState, useEffect, useRef } from "react";
import Dropdown from './Dropdown';

const MenuItem = ({items, depthLevel}) => {
    const [dropdown, setDropdown] = useState(false);
    const closeDropdown = () => {
        dropdown && setDropdown(false);
      };
    let ref = useRef();
    useEffect(() => {
        const handler = (event) => {
         if (dropdown && ref.current && !ref.current.contains(event.target)) {
          setDropdown(false);
         }
        };
        document.addEventListener("mousedown", handler);
        document.addEventListener("touchstart", handler);
        return () => {
         // Cleanup the event listener
         document.removeEventListener("mousedown", handler);
         document.removeEventListener("touchstart", handler);
        };
       }, [dropdown]);
       const onMouseEnter = () => {
        window.innerWidth > 960 && setDropdown(true);
       };
       
       const onMouseLeave = () => {
        window.innerWidth > 960 && setDropdown(false);
       };
  return (
    <li className="menu-items" ref={ref} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} onClick={closeDropdown}>
      {items.submenu ? (
        <>
          <button type="button" aria-haspopup="menu" 
          aria-expanded={dropdown ? "true" : "false"}
          onClick={() => setDropdown((prev) => !prev)}>
            <b>{items.title}{' '}</b>
            {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
          </button>
          <Dropdown submenus={items.submenu} dropdown={dropdown} depthLevel={depthLevel}  />
        </>
      ) : (
        <a href={items.url}><b>{items.title}</b></a>
      )}
    </li>
  );
};

export default MenuItem;