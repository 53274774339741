import React from 'react';
import "../../../../static/css/default.css"
import ContentsHeader from '../../../Layout/Contents_Header'
import KT_1 from '../../../../static/img/business/cloud/kt/public.jpg'
import KT_2 from '../../../../static/img/business/cloud/kt/finance.jpg'
import KT_3 from '../../../../static/img/business/cloud/kt/logistic.jpg'
import KT_4 from '../../../../static/img/business/cloud/kt/security.jpg'
import aicc from '../../../../static/img/business/cloud/kt/aicc.jpg'
import server from '../../../../static/img/business/cloud/kt/Server_image.jpg'
import cloudfarm from '../../../../static/img/business/cloud/kt/CloudFarm_image.jpg'
import dbaas from '../../../../static/img/business/cloud/kt/DBaaS_for_MySQL_8_image.jpg'
import nas from '../../../../static/img/business/cloud/kt/NAS_image.jpg'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { MDBRow, MDBCol } from "mdbreact";
const Ktcloud = () => {

    return (
        <>
            <ContentsHeader name='cloud' />
            <div className='context'>
                <div className='comment'>
                    <h2><b>KT Cloud</b></h2>
                    <h4>강력하고 안정적인 서버와 스토리지 그리고 네트워크 인프라가 필요하세요?</h4>
                    KT 클라우드를 가장 잘 이해하는 빅클라우드. KT 클라우드의 파트너로서, 가장 저렴하고 편리하게 KT 클라우드를 만나보세요. 원하는 클라우드 컴퓨팅 서비스를 가장 빠르고 정확하게 제공합니다.<br />
                </div>
                <br />
                <h4 style={{ display: 'inline' }}>
                    Solution
                </h4>
                <br /><br />
                <MDBRow>
                    <MDBCol size='2'>
                        <Card border="info">
                            <Card.Link href='https://vikcloud.co.kr/contents/cloud/ktcloud.html' target='_blank' rel="noopener noreferrer">
                                <Card.Img variant="top" src={KT_1} width='80%' />
                            </Card.Link>
                            <Card.Body>
                                <Card.Title><b>공공 Cloud</b></Card.Title>
                                <Card.Text>
                                    변하는 공공 Cloud 정책을 이해하는 KT만의 노하우
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </MDBCol>
                    <MDBCol size='2'>
                        <Card border="info">
                            <Card.Link href='https://vikcloud.co.kr/contents/cloud/ktcloud.html' target='_blank' rel="noopener noreferrer"><Card.Img variant="top" src={KT_2} width='80%' /></Card.Link>
                            <Card.Body>
                                <Card.Title><b>금융 Cloud</b></Card.Title>
                                <Card.Text>
                                    업무환경부터 핀테크까지 특화된 IT와 금융의 Hybrid.
                                </Card.Text>
                                {/*<Button variant="primary" style={{ width: "100%" }} href='https://vikcloud.co.kr/contact-2/?mod=editor&pageid=1' target='_blank'>문의 하기</Button>*/}
                            </Card.Body>
                        </Card>
                    </MDBCol>
                    <MDBCol size='2'>
                        <Card border="info">
                            <Card.Link href='https://vikcloud.co.kr/contents/cloud/ktcloud.html' target='_blank' rel="noopener noreferrer"><Card.Img variant="top" src={KT_3} width='80%' /></Card.Link>
                            <Card.Body>
                                <Card.Title><b>물류/제조 Cloud</b></Card.Title>
                                <Card.Text>
                                    서버 구축부담을 덜고 전문화된 공장 자동화 플랫폼
                                </Card.Text>
                                {/*<Button variant="primary" style={{ width: "100%" }} href='https://vikcloud.co.kr/contact-2/?mod=editor&pageid=1' target='_blank'>문의 하기</Button>*/}
                            </Card.Body>
                        </Card>
                    </MDBCol>
                    <MDBCol size='2'>
                        <Card border="info">
                            <Card.Link href='https://vikcloud.co.kr/contents/cloud/ktcloud.html' target='_blank' rel="noopener noreferrer"><Card.Img variant="top" src={KT_4} width='80%' /></Card.Link>
                            <Card.Body>
                                <Card.Title><b>보안 Cloud</b></Card.Title>
                                <Card.Text>
                                    ISMS, CSAP, ISO 27001 정보보호 인증
                                </Card.Text>
                                {/*<Button variant="primary" style={{ width: "100%" }} href='https://vikcloud.co.kr/contact-2/?mod=editor&pageid=1' target='_blank'>문의 하기</Button>*/}
                            </Card.Body>
                        </Card>
                    </MDBCol>
                    <MDBCol size='2'>
                        <Card border="info">
                        <Card.Link href='https://vikcloud.co.kr/contents/cloud/ktcloud.html' target='_blank' rel="noopener noreferrer"><Card.Img variant="top" src={aicc} width='80%' /></Card.Link>
                            <Card.Body>
                                <Card.Title><b>Hyperscale AI Computing</b></Card.Title>
                                <Card.Text>
                                    GPU Cluster를 가상화하여 연산을 실행할 때 GPU 자원을 동적 할당
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </MDBCol>
                </MDBRow>
                <div className='solid'></div>
                <br />
                <h4 style={{ display: 'inline' }}>
                    Product
                </h4>
                <br /><br />
                <MDBRow>
                    <MDBCol size='2'>
                        <Card border="success">
                        <Card.Link href='https://vikcloud.co.kr/contents/cloud/ktcloud.html' target='_blank' rel="noopener noreferrer"><Card.Img variant="top" src={server} /></Card.Link>
                            <Card.Body>
                                <Card.Title><b>Server</b></Card.Title>
                                <Card.Text>
                                    최고 품질을 자랑하는 가상화 Server(Network, Disk 포함)를 종량제 요금으로 제공
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </MDBCol>
                    <MDBCol size='2'>
                        <Card border="success">
                        <Card.Link href='https://vikcloud.co.kr/contents/cloud/ktcloud.html' target='_blank' rel="noopener noreferrer"><Card.Img variant="top" src={cloudfarm} /></Card.Link>
                            <Card.Body>
                                <Card.Title><b>CloudFarm</b></Card.Title>
                                <Card.Text>
                                CloudFarm은 kt cloud의 경험 그대로 Hybrid Cloud 인프라를 제공하는 완전 관리형 솔루션
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </MDBCol>
                    <MDBCol size='2'>
                        <Card border="success">
                        <Card.Link href='https://vikcloud.co.kr/contents/cloud/ktcloud.html' target='_blank' rel="noopener noreferrer"><Card.Img variant="top" src={dbaas} /></Card.Link>
                            <Card.Body>
                                <Card.Title><b>DBaaS for MySQL 8</b></Card.Title>
                                <Card.Text>
                                 MySQL 데이터베이스를 손쉽게 구축하고 운영할 수 있으며 클러스터를 편하게 구성할 수 있는 서비스
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </MDBCol>
                    <MDBCol size='2'>
                        <Card border="success">
                        <Card.Link href='https://vikcloud.co.kr/contents/cloud/ktcloud.html' target='_blank' rel="noopener noreferrer"><Card.Img variant="top" src={nas} /></Card.Link>
                            <Card.Body>
                                <Card.Title><b>NAS</b></Card.Title>
                                <Card.Text>
                                Network를 통해 제공받는 고성능의 Storage 서비스를 종량제 요금으로 제공
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </MDBCol>
                </MDBRow>
                <br />
            </div>

        </>
    )
};

export default Ktcloud;