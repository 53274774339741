import React from 'react';
import "../../../../static/css/default.css"   // default.css 호출
//-------------------------------------------
import ContentsHeader from '../../../Layout/Contents_Header'
//-------------------------------------------
import containment_1 from '../../../../static/img/containment/1.jpg' 
import containment_2 from '../../../../static/img/containment/2.jpg'
import containment_3 from '../../../../static/img/containment/3.jpg'
import containment_4 from '../../../../static/img/containment/4.jpg'
//-------------------------------------------
import ac_inverter from '../../../../static/img/business/datacenter/hvac/ac_inverter.jpg'
import dc_inverter from '../../../../static/img/business/datacenter/hvac/dc_inverter.jpg'
import air from '../../../../static/img/business/datacenter/hvac/air.jpg'
import water from '../../../../static/img/business/datacenter/hvac/water.jpg'
import water_2 from '../../../../static/img/business/datacenter/hvac/water_2.jpg'
//-------------------------------------------
import Card from 'react-bootstrap/Card';
import { MDBRow, MDBCol } from "mdbreact";

const Hvac = () => {
    return (
        <>
            <ContentsHeader name='pdu' />
            <div className='context'>
                <div className='comment'>
                    <h2>
                        <b>컨테인먼트</b>
                    </h2><br />
                    데이터센터의 컨테인먼트는 각종 IT장비에서 배출되는 더운 공기와 실내로 공급되는 차가운 공기의 흐름을 분리하는 것에 목적이 있습니다.<br />
                    데이터센터 및 서버 룸, 전산실에 적합한 최적의 효율성에 도달하려면 레이아웃, 예산 및 장기 목표를 확인해야 합니다.<br />
                    주호시스템즈는 각각의 요구 사항에 적합한 솔루션을 제안하고 각 옵션을 보다 면밀히 분석, 검토하여 전체 데이터센터 냉각 전략 및 다양한 방식의 온습도 관리 인프라와 전력 분배 전략을 제안합니다.<br />
                </div>

                <div className="new_img">
                    <table>
                        <tr>
                            <td>
                                <img
                                    src={containment_1}
                                    alt='...'
                                    height='152px'
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <h5 style={{ fontSize: '15px' }}><b>SCRS / Self Containment Racking System</b></h5>
                                에스씨알에스
                            </td>
                            <td>
                                <img
                                    src={containment_2}
                                    alt='...'
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <h5 style={{ fontSize: '15px' }}><b>Legacy Remodeling</b></h5>
                                레거시 리모델링
                            </td>
                            <td>
                                <img
                                    src={containment_3}
                                    alt='...'
                                />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                <h5 style={{ fontSize: '15px' }}><b>Cold Aisle Containment</b></h5>
                                콜드 에일 컨테인먼트
                            </td>

                            <td>
                                <img
                                    src={containment_4}
                                    alt='...'
                                />
                                <h5 style={{ fontSize: '15px' }}><b>Hot Aisle Containment</b></h5>
                                핫 에일 컨테인먼트
                            </td>
                        </tr>
                    </table>
                </div>
                <div className='solid'></div>
                <div className='comment'>
                    <h2 style={{ display: 'inline' }}>
                    <b>항온항습기</b>
                    </h2>
                    <br /><br />
                    데이터센터의 항온항습기는 특정한 온도 그리고 습도를 유지하는 것에 목적이 있습니다. 서버와 네트워크 장비는 온도와 습도에 매우 민감합니다.<br />
                    데이터센터를 안정적으로 운영하기 위해선 항온항습기를 이용하여 적정 온도/습도를 유지해야 합니다.
                </div>
                <div className="new_img">
                    <MDBRow>
                        <MDBCol size='4'>
                            <Card border='info'>
                                <Card.Body>
                                <Card.Link href='/contact' target='_blank'><Card.Img variant="top" src={ac_inverter} width='80%' /></Card.Link>
                                    <Card.Title><b>AC 인버터 항온항습기</b></Card.Title>
                                    <Card.Text as='p'>
                                    </Card.Text>
                                    {/*<Button variant="primary" style={{ width: "100%" }} href='/contact'>문의 하기</Button>*/}
                                </Card.Body>
                            </Card>
                        </MDBCol>
                        <MDBCol size='4'>
                            <Card border="info">
                                <Card.Body>
                                <Card.Link href='/contact' target='_blank'><Card.Img variant="top" src={water} width='80%' /></Card.Link>
                                    <Card.Title><b>수냉식 항온항습기</b></Card.Title>
                                    <Card.Text>
                                    </Card.Text>
                                    {/*<Button variant="primary" style={{ width: "100%" }} href='/contact'>문의 하기</Button>*/}
                                </Card.Body>
                            </Card>
                        </MDBCol>
                        <MDBCol size='4'>
                            <Card border="info">
                                <Card.Body>
                                <Card.Link href='/contact' target='_blank'><Card.Img variant="top" src={water_2} width='80%' /></Card.Link>
                                    <Card.Title><b>수방식 항온항습기</b></Card.Title>
                                    <Card.Text>
                                       
                                    </Card.Text>
                                    {/*<Button variant="primary" style={{ width: "100%" }} href='/contact'>문의 하기</Button>*/}
                                </Card.Body>
                            </Card>
                        </MDBCol>
                        </MDBRow>
                    <MDBRow style={{ marginTop: '2%' }}>
                    <MDBCol size='4'>
                            <Card border="info">
                                <Card.Body>
                                <Card.Link href='/contact' target='_blank'><Card.Img variant="top" src={dc_inverter} width='80%' /></Card.Link>
                                    <Card.Title><b>DC 인버터 항온항습기</b></Card.Title>
                                    <Card.Text  as='p'>
                                        
                                    </Card.Text>
                                    {/*<Button variant="primary" style={{ width: "100%" }} href='/contact'>문의 하기</Button>*/}
                                </Card.Body>
                            </Card>
                        </MDBCol>
                        <MDBCol size='4' md='4'>
                            <Card border="info">
                                <Card.Body>
                                <Card.Link href='/contact' target='_blank'><Card.Img variant="top" src={air} width='80%' /></Card.Link>
                                    <Card.Title><b>공냉식 항온항습기</b></Card.Title>
                                    <Card.Text>
                                    </Card.Text>
                                    {/*<Button variant="primary" style={{ width: "100%" }} href='/contact'>문의 하기</Button>*/}
                                </Card.Body>
                            </Card>
                        </MDBCol>
                    </MDBRow>
                </div>
            </div>
        </>
    );
};

export default Hvac;