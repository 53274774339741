import React from 'react';
import "../../../../static/css/default.css"
import ContentsHeader from '../../../Layout/Contents_Header'
import both_1 from '../../../../static/img/rack/both/1.jpg'
import both_2 from '../../../../static/img/rack/both/2.jpg'
import both_3 from '../../../../static/img/rack/both/3.jpg'
import server_1 from '../../../../static/img/rack/server/1.jpg'
import server_2 from '../../../../static/img/rack/server/2.jpg'
import server_3 from '../../../../static/img/rack/server/3.jpg'
import core_1 from '../../../../static/img/rack/core/1.jpg'
import core_2 from '../../../../static/img/rack/core/2.jpg'
import core_3 from '../../../../static/img/rack/core/3.jpg'
import { Card, Button, Col, Row } from 'react-bootstrap';
import Guide1 from '../../../../static/pdf/cabling_guide.pdf'
import Otdr1 from '../../../../static/img/otdr/1.jpg'
import Otdr2 from '../../../../static/img/otdr/2.png'
import Wiring from '../../../../static/img/wiring.jpg'

const Equipment = () => {
    return (
        <>
            <ContentsHeader name='datacenter' />
            <div className='context'>
            <div className='comment'>
                <h2>
                <b>통합배선</b>
                </h2>
                <br />수직 케이블 관리 , 수평 케이블 관리 및 랙 솔루션으로 구성된 Panduit의 폭 넓은 제품군은<br/>고용량 케이블을 포설, 관리, 보호하는 데 요구되는 공간을 줄임으로써 총 소유 비용을 최소화합니다 .
            </div>
            <div className="datacenter">
                <Row>
                    <Col>
                <img
                        src={Wiring}
                        alt='...'
                    />
                    </Col>
                    <Col>
                <Card border="white">
                    <Card.Body>
                        <Card.Title><b>통합배선 케이블링 가이드 파일(PDF)</b></Card.Title>
                        <Card.Text>
                            주호시스템즈의 통합배선 케이블링 가이드 입니다.
                            &nbsp;&nbsp;&nbsp;&nbsp;<br/>
                            &nbsp;&nbsp;&nbsp;<br/>
                        </Card.Text>
                        <Button variant="primary" style={{width: "100%"}} href={Guide1} target='_blank'>열어 보기</Button>
                    </Card.Body>
                </Card>
                </Col>
                </Row>
            </div>
            <div className='solid'></div>
                <div className='comment'>
                    <h2>
                    <b>랙</b>
                    </h2>
                    <br />
                    대형 데이터센터를 위한 선결 조건은 열처리를 위한 냉각 시스템과 효율적인 유지 관리를 위한 케이블링 시스템입니다. <br />
                    경제적이고 설치가 간편한 IDC 전용 시스템 랙이며 랙의 쓰임에 따라 맞춤형 솔루션을 제공합니다.
                </div>
                <div className="new_img">
                    <table>
                        <tr>
                            <td style={{ borderRight: '1px solid #bdbdbd' }}>
                                <img
                                    src={both_1}
                                    alt='...'
                                />

                                <img
                                    src={both_2}
                                    alt='...'
                                />
                                <img
                                    src={both_3}
                                    alt='...'
                                />
                                <br /><br />
                                <center><h5 style={{ display: 'inline', marginTop: '3%' }}><b>Server & Core Network </b></h5><br/>서버 & 코어 네트워크</center>
                            </td>
                            <td style={{ borderRight: '1px solid #bdbdbd' }}>
                                <img
                                    src={server_1}
                                    alt='...'
                                />

                                <img
                                    src={server_2}
                                    alt='...'
                                />
                                <img
                                    src={server_3}
                                    alt='...'
                                />
                                <br /><br />
                                <center><h5 style={{ display: 'inline' }}><b>Server </b></h5><br/>서버</center>

                            </td>
                            <td>
                                <img
                                    src={core_1}
                                    alt='...'
                                />
                                <img
                                    src={core_2}
                                    alt='...'
                                />
                                <img
                                    src={core_3}
                                    alt='...'
                                />
                                <br /><br />
                                <center><h5 style={{ display: 'inline' }}><b>Core Network </b></h5><br/>코어 네트워크</center>
                            </td>
                        </tr>
                    </table>
                </div>
                <div className='solid'></div>
                <div className='comment'>
                    <h2>
                    <b>측정장비</b>
                    </h2>
                    <br />
                    1. OTDR(광섬유 시험기)<br/>
                    2. 광테스터<br/>
                    3. 광심선대조기<br />
                    4. Optical Power Meter(광 파워 미터)
                </div>
                <div className="new_img">
                <table>
                        <tr>
                            <td>
                                <img
                                    src={Otdr1}
                                    alt='...'
                                />

                                <img
                                    src={Otdr2}
                                    alt='...'
                                    style={{width:'40%'}}
                                />
                            </td>
                        </tr>
                    </table>
                </div>
                
                
            </div>
        </>
    );
};

export default Equipment;