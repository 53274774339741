import React from 'react';
import "../../../../static/css/default.css"
import ContentsHeader from '../../../Layout/Contents_Header'
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import { MDBRow, MDBCol } from "mdbreact";
import ad from '../../../../static/img/business/cloud/aws/ad_and_marketing_image.jpg'
import financial from '../../../../static/img/business/cloud/aws/financial_image.jpg'
import game from '../../../../static/img/business/cloud/aws/game_tech_image.jpg'
import media from '../../../../static/img/business/cloud/aws/media_and_ent_image.jpg'
import computing from '../../../../static/img/business/cloud/aws/computing_image.png'
import database from '../../../../static/img/business/cloud/aws/database_image.png'
import serverless from '../../../../static/img/business/cloud/aws/serverless_image.png'
import storage from '../../../../static/img/business/cloud/aws/storage_image.png'
import container from '../../../../static/img/business/cloud/aws/container.png'
const Aws = () => {
    return (
        <>
            <ContentsHeader name='cloud' />
            <div className='context'>
                <div className='comment'>
                    <h2>AWS</h2>
                    <h4>VIKCLOUD는 세계 최대 클라우드 업체인 AWS의 공식 컨설팅 파트너입니다.</h4>
                    고객 여러분의 어려움을 먼저 파악하여 성공적인 글로벌 사업의 가이드라인을 제시해 드립니다<br />
                </div>
                <br />
                <h4 style={{ display: 'inline' }}>
                    Solution
                </h4>
                <br /><br />
                <MDBRow>
                    <MDBCol size='2'>
                        <Card border="info">
                            <Card.Link href='https://vikcloud.co.kr/contents/cloud/aws.html' target='_blank' rel="noopener noreferrer">
                                <Card.Img variant="top" src={ad} />
                            </Card.Link>
                            <Card.Body>
                                <Card.Title><b>광고 및 마케팅</b></Card.Title>
                                <Card.Text>s
                                    페타바이트 규모의 분석 워크로드와 한자릿수 지연 시간(밀리초) 워크로드의 경제성을 실현합니다.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </MDBCol>
                    <MDBCol size='2'>
                        <Card border="info">
                            <Card.Link href='https://vikcloud.co.kr/contents/cloud/aws.html' target='_blank' rel="noopener noreferrer">
                                <Card.Img variant="top" src={financial} />
                            </Card.Link>
                            <Card.Body>
                                <Card.Title><b>금융 서비스</b></Card.Title>
                                <Card.Text>

                                    비용 절감. 복원력 개선. 뱅킹, 결제, 자본 시장, 보험 분야의 AWS 솔루션을 살펴봅니다.

                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </MDBCol>
                    <MDBCol size='2'>
                        <Card border="info">
                            <Card.Link href='https://vikcloud.co.kr/contents/cloud/aws.html' target='_blank' rel="noopener noreferrer">
                                <Card.Img variant="top" src={game} />
                            </Card.Link>
                            <Card.Body>
                                <Card.Title><b>Game Tech</b></Card.Title>
                                <Card.Text>
                                    장르와 플랫폼을 막론하고 막대한 컴퓨팅 파워를 필요로 하는 게임을 만듭니다.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </MDBCol>
                    <MDBCol size='2'>
                        <Card border="info">
                            <Card.Link href='https://vikcloud.co.kr/contents/cloud/aws.html' target='_blank' rel="noopener noreferrer">
                                <Card.Img variant="top" src={media} width='60%' />
                            </Card.Link>
                            <Card.Body>
                                <Card.Title><b>미디어 및 엔터테인먼트</b></Card.Title>
                                <Card.Text>

                                    더 빠르고 스마트하며 효율적으로 움직일 수 있도록 특별히 구축된 미디어 솔루션을 만나보세요.

                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </MDBCol>
                </MDBRow>
                <div className='solid'></div>
                <br />
                <h4 style={{ display: 'inline' }}>
                    Product
                </h4>
                <br /><br />
                <MDBRow>
                    <MDBCol size='3'>
                        <Card border="success">
                            <Card.Link href='https://vikcloud.co.kr/contents/cloud/aws.html' target='_blank' rel="noopener noreferrer">
                                <Card.Img variant="top" src={computing} width='60%' />
                            </Card.Link>
                            <Card.Body>
                                <Card.Title><b>컴퓨팅</b></Card.Title>
                                <Card.Text>

                                    세계에서 가장 안정적이고 안전하며 많은 기능을 제공하는 클라우드에서 인프라 비용을 절감하고 혁신을 가속화할 수 있도록 지원합니다.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </MDBCol>
                    <MDBCol size='3'>
                        <Card border="success">
                            <Card.Link href='https://vikcloud.co.kr/contents/cloud/aws.html' target='_blank' rel="noopener noreferrer">
                                <Card.Img variant="top" src={container} width='60%' />
                            </Card.Link>
                            <Card.Body>
                                <Card.Title><b>컨테이너</b></Card.Title>
                                <Card.Text>
                                    AWS 컨테이너 서비스를 사용하면 온프레미스 또는 클라우드에서 기반 인프라를 손쉽게 관리하여 혁신 및 비즈니스 요구 사항에 집중할 수 있습니다.
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </MDBCol>

                    <MDBCol size='3'>
                        <Card border="success">
                            <Card.Link href='https://vikcloud.co.kr/contents/cloud/aws.html' target='_blank' rel="noopener noreferrer">
                                <Card.Img variant="top" src={serverless} width='60%' />
                            </Card.Link>
                            <Card.Body>
                                <Card.Title><b>서버리스</b></Card.Title>
                                <Card.Text>
                                    AWS는 코드 실행, 데이터 관리 및 애플리케이션 통합을 위한 기술을 서버를 관리할 필요 없이 제공합니다.
                                    <br /><br />
                                </Card.Text>
                            </Card.Body>
                        </Card>
                    </MDBCol>
                </MDBRow>
                <br />
                <MDBRow>
                    <MDBCol size='3'>
                        <Card border="success">
                            <Card.Link href='https://vikcloud.co.kr/contents/cloud/aws.html' target='_blank' rel="noopener noreferrer">
                                <Card.Img variant="top" src={database} width='60%' />
                            </Card.Link>
                            <Card.Body>
                                <Card.Title><b>데이터베이스</b></Card.Title>
                                <Card.Text>
                                    사용 사례를 중심으로 특정 요구 사항에 적합한 고도로 확장 가능한 분산 애플리케이션을 구축할 수 있습니다.
                                    <br /><br />
                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </MDBCol>
                    <MDBCol size='3'>
                        <Card border="success">
                            <Card.Link href='https://vikcloud.co.kr/contents/cloud/aws.html' target='_blank' rel="noopener noreferrer">
                                <Card.Img variant="top" src={storage} width='60%' />
                            </Card.Link>
                            <Card.Body>
                                <Card.Title><b>스토리지</b></Card.Title>
                                <Card.Text>
                                    수백만 명의 고객이 AWS 스토리지 서비스를 사용하여 비즈니스를 전환하고 민첩성을 개선하고 비용을 낮추며 혁신을 가속화합니다.
                                </Card.Text>

                            </Card.Body>
                        </Card>
                    </MDBCol>
                </MDBRow>
                <br />
            </div>

        </>
    )
};

export default Aws;