/* eslint-disable no-restricted-globals */
import React, { useRef, useState } from 'react';
import "../../../static/css/default.css"
import ContentsHeader from '../../Layout/Contents_Header';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { MDBCheckbox } from 'mdb-react-ui-kit';
import axios from 'axios';

export const Contact = () => {
    const [company, setCompany] = useState('');
    const [department, setDepartment] = useState('');
    const [name, setName] = useState('');
    const [number, setNumber] = useState('');
    const [mail, setMail] = useState('');
    const [category, setCategory] = useState('');
    const [content, setContent] = useState('');

    const companyHandler = (e) => {
        e.preventDefault();
        setCompany(e.target.value);
    }
    const departHandler = (e) => {
        e.preventDefault();
        setDepartment(e.target.value);
    }
    const nameHandler = (e) => {
        e.preventDefault();
        setName(e.target.value);
    }
    const numberHandler = (e) => {
        e.preventDefault();
        setNumber(e.target.value);
    }
    const mailHandler = (e) => {
        e.preventDefault();
        setMail(e.target.value);
    }
    const categoryHandler = (e) => {
        e.preventDefault();
        setCategory(e.target.value);
    }
    const contentHandler = (e) => {
        e.preventDefault();
        setContent(e.target.value.replace(/(?:\r\n|\r|\n)/g, '<br />'));
    }
    const form = useRef();
    const sendEmail = async (e) => {
        e.preventDefault();
        const client = axios.create()
        if (category === '') {
            alert('문의 유형을 선택하지 않았습니다.\n문의 유형을 선택해 주세요');
        } else {
            //console.log(file);
            client.post('/mail', {
                data: {
                    company: company,
                    department: department,
                    name: name,
                    number: number,
                    mail: mail,
                    category: category,
                    content: content,
                    //agree : document.getElementById('agree').value,
                    //file: file,
                }
            }).then((response) => {
                console.log(response.data);
            })
            alert('문의가 접수 되었습니다.\n잠시 후 연락 드리겠습니다.')
            location.href = "http://juhosystems.com/contact";
        }
    }

    return (
        <>
            <ContentsHeader name='contact' />
            <div className='context_contact' style={{marginLeft: '8%'}}>
                <h2>
                    <b>문의</b>
                </h2><br />
                {/*</div><form encType="multipart/form-data" method="post" onSubmit={sendEmail}>*/}
                <Form ref={form} encType="multipart/form-data" method="post" onSubmit={sendEmail}>
                    {/*<Form action='/mail' method='post'>*/}
                    <Row className="mb-4" style={{ width: "72%" }}>
                        <Form.Group as={Col} controlId="company">
                            <Form.Label>회사명</Form.Label>
                            <Form.Control type="text" required onChange={companyHandler} value={company} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="department">
                            <Form.Label>부서 및 직책</Form.Label>
                            <Form.Control type="text" required onChange={departHandler} value={department} />
                        </Form.Group>
                    </Row>
                    <Row className="mb-4" style={{ width: "72%" }}>
                        <Form.Group as={Col} controlId="name">
                            <Form.Label>이름</Form.Label>
                            <Form.Control type="text" required onChange={nameHandler} value={name} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="number">
                            <Form.Label>전화번호</Form.Label>
                            <Form.Control type="text" required onChange={numberHandler} value={number} />
                        </Form.Group>
                    </Row>
                    <Row className="mb-4" style={{ width: "72%" }}>
                        <Form.Group as={Col} controlId="mail">
                            <Form.Label>이메일</Form.Label>
                            <Form.Control type="email" required onChange={mailHandler} value={mail} />
                        </Form.Group>
                        <Form.Group as={Col} controlId="category">
                            <Form.Label>문의 유형</Form.Label>
                            <Form.Select required onChange={categoryHandler} >
                                <option selected>문의유형 선택</option>
                                <option value="DataCenter">데이터센터 솔루션</option>
                                <option value="Cloud">클라우드</option>
                                <option value="Etc">기타</option>
                            </Form.Select>
                        </Form.Group>
                    </Row>
                    <Form.Group className="mb-4" controlId="content">
                        <Form.Label>문의 내용</Form.Label>
                        <Form.Control as="textarea" rows={8} required onChange={contentHandler} style={{ width: "70%" }} />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="content">
                        <Form.Label>개인정보처리방침</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={10}
                            placeholder="주호시스템즈(“회사”)는 아래 내용에 따라 귀하의 정보를 수집 및 활용 합니다.&#13;&#13;1. 개인정보 수집자 : ㈜주호시스템즈
                            2. 수집 받는 개인 정보
                            &nbsp;&nbsp;&nbsp;- 회사명, 이름, 직급, 부서, 연락처, 이메일주소, 문의내용
                            3. 수집/이용 목적
                            &nbsp;&nbsp;&nbsp;- 상담 문의 처리, 이벤트 참여 확인 및 당첨자 안내
                            &nbsp;&nbsp;&nbsp;- (마케팅 활용 동의 시) 관련 신규 상품 소개, 서비스 제공 및 이용, 행사 안내 (이메일 또는 전화, 문자 알림)
                            4. 보유 및 이용 기간 : 수집일로부터 2년 (고객동의 철회 시 지체없이 파기)&#13;
                            ※ 개인정보 이용 철회 방법 
                            &#10;&#10;&#10;- 안내 문자 등의 동의 철회를 이용하는 방법 : 이메일 수신 거부 링크 클릭 또는 안내 문자 내 수신거부 연락처를 통한 수신 거부 의사 통보 
                            &#10;&#10;&#10;- 개인정보 처리 상담 부서 
                            &#10;&#10;&#10;&#10;&#10;&#10;- 부서명: 컨설팅팀 
                            &#10;&#10;&#10;&#10;&#10;&#10;- 연락처: 1577-6077, consulting@vikcloud.net&#13;
                            ※ 동의거부권 및 불이익 귀하는 동의를 거절할 수 있는 권리를 보유하며, 동의를 거절하는 경우 상기 이용 목적에 명시된 서비스가 제공되지 아니합니다."
                            readOnly
                            style={{ 
                                width: "70%",
                            }}
                        />
                    </Form.Group>
                    <Form.Group className="mb-4" controlId="agree" >
                        <MDBCheckbox name='flexCheck' id='agree' label='개인정보처리방침에 동의합니다.' required/><br />
                    </Form.Group>
                    <Button type="submit">문의 보내기</Button>
                </Form>
            </div>
        </>
    );
};