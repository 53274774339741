import React from 'react';
import "../../../static/css/default.css"
import "../../../static/css/years.css"
import kt_cloud_logo from '../../../static/img/ktCloudLogo.png'
import aws_logo from '../../../static/img/awsLogo.png'
import ds_logo from '../../../static/img/digitalservice.png'
import db_logo from '../../../static/img/thales.svg'
import ContentsHeader from '../../Layout/Contents_Header';
import Corning from '../../../static/img/Corning.png';
import Panduit from '../../../static/img/partner/panduit.png';


const Years = () => {
    return (
        <>
        <ContentsHeader name="company"/>
            <br/>
            <br/>
        <body className='history_body'>
            <h1 style={{marginLeft: '5%'}}><b>연혁</b></h1>
            <br/>
            <br/>
            <div className="history" style={{marginLeft: '5%'}}>
                    <div>
                        <h2><b>2022년</b></h2>
                        <ol>
                            <li><b>KT 마켓플레이스 로그세이버 출시</b></li>
                            <img
                                src={ds_logo}
                                alt='...'
                                width='170'
                                style={{marginLeft: 'auto', marginBottom: '0.7%', marginTop:'2%'}}
                            />
                            <li><b>디지털 전문계약제도 공공기관 KT G클라우드 중개사업자 선정</b></li>
                            <img
                                src={Panduit}
                                alt='...'
                                width='120'
                                style={{marginLeft: 'auto', marginBottom: '0.7%', marginTop:'2%'}}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <img
                                src={Corning}
                                alt='...'
                                width='120'
                                style={{marginLeft: 'auto', marginBottom: '0.7%', marginTop:'2%'}}
                            />
                            <li><b>통합배선 솔루션 파트너</b></li>
                        </ol>
                        <h2><b>2021년</b></h2>
                        <ol>
                        <img
                                src={kt_cloud_logo}
                                alt='...'
                                width='100'
                                style={{marginLeft: 'auto', marginBottom: '0.7%'}}
                            />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <img
                                src={aws_logo}
                                alt='...'
                                WIDTH='4%'
                                style={{marginLeft: 'auto', marginBottom: '0.7%'}}
                            />
                            <li><b>조달 등록</b></li>
                            <li><b>KT클라우드 매니지드 총판</b></li>
                            <li><b>AWS 파트너사 등록</b></li>
                            <li><b>로그세이버 클라우드 총판</b></li>
                        </ol>

                        <div></div>
                        <h2><b>2015년 ~ 2020년</b></h2>
                        <ol>
                            <img
                                src={db_logo}
                                alt='...'
                                width='150'
                                style={{marginLeft: 'auto', marginBottom:'0.7%'}}
                            />
                            <li><b>망 이중화 솔루션 총판</b></li>
                            <li><b>DB 보안 솔루션 총판</b></li>
                            <li><b>설립</b></li>
                        </ol>
                    </div>
            </div>
        </body>
        </>
    );
};

export default Years;
