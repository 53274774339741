import React from 'react';
import "../../../../static/css/default.css"
import ContentsHeader from '../../../Layout/Contents_Header'
//import pdu from '../../../../static/img/contentsHeader/pdu.jpg'
import pdu1 from '../../../../static/img/pdu/1.jpg'
import Card from 'react-bootstrap/Card';
import { Row, Col, Button } from 'react-bootstrap';
import pdu from '../../../../static/img/business/datacenter/electrical/APC_PDU.jpg'
import pdu2 from '../../../../static/img/business/datacenter/electrical/APC_PDU_old.png'
import ups1 from '../../../../static/img/business/datacenter/electrical/online_ups_image.JPG'
import ups2 from '../../../../static/img/business/datacenter/electrical/line-interactive_ups_image.JPG'
import ups3 from '../../../../static/img/business/datacenter/electrical/offline_ups_image.jpg'
import { MDBRow, MDBCol } from "mdbreact";

const Electrical = () => {
    return (
        <>
            <ContentsHeader name='pdu' />
            <div className='context'>
                <div className='comment'>
                    <h2 style={{ display: 'inline' }}>
                        <b>PDU</b>
                    </h2>
                    (전력 분배 장치)<br /><br />
                    전력 분배 장치(Power Distribution Unit, PDU)는 전력 공급망의 마지막 링크로서, IT 부하에 중요한 전력을 공급합니다.<br />
                    PDU는 데이터 센터 내 모든 유형의 IT 장비에 전력을 분배할 수 있게 설계되어 있습니다.<br />
                    PDU는 전원을 생성하지 않고, 사용 가능한 전원으로부터 공급되는 전력을 분배합니다.<br />
                </div>
                <div style={{ verticalAlign: 'middle' }}>
                        <img
                        src={pdu}
                        alt='...'
                        width='390px'
                        />
                        <img
                        src={pdu2}
                        alt='...'
                        width='550px'
                        style={{marginTop: '4%', marginLeft: '3%'}}
                        />
                    <br />
                </div>
                <div className='solid'></div>
                <div className='comment'>
                    <h2 style={{ display: 'inline' }}>
                        <b>UPS</b>
                    </h2>
                    (무정전 전원 장치)<br /><br />
                    무정전 전원 장치(Uninterruptible Power Supply system)는 평소에 전력공급과 동시에 배터리를 충전하다가 정전시 충전한 배터리를 통해 전력을 공급합니다.<br />
                    데이터센터에 자연재해나 인재 등으로 인하여 정전이 될 경우 서버와 네트워크 장비에는 심각한 손상이 발생할 수 있습니다.<br />
                    그러나 UPS를 이용하게 되면 정전시에도 충전한 UPS 배터리로 전력을 공급하여 해당 문제를 해결할 수 있습니다.
                </div>
                <div className="new_img">
                    <Row>
                        <Col xs lg='3'>
                            <Card border='info'>
                                <Card.Link href='/contact' target='_blank' rel="noopener noreferrer">
                                    <Card.Img variant="top" src={ups1} width='60%' />
                                </Card.Link>
                                <Card.Body style={{ display: 'inline-block' }}>
                                    <Card.Title><b>ON-LINE</b></Card.Title>
                                    <Card.Text as='p'>
                                        {/*ON-LINE 방식의 특징은 배터리모드로 동작시 절체시간이 없으며 입력전압이 변동되도 출력전압은 설정한 갑으로 일정하게 출력합니다.. 입력전원에 예민한 부하에 적합합니다.
                                        다만 바이패스 동작시에는 절체시간이 존재합니다.
                                        정상운전시 정류부(충전부)를 통하여 배터리에 전원을 공급하는 동시에 인버터를 통하여 부하에 전원을 공급합니다.*/}
                                        AC를 DC로 바꾸고, 다시 DC를 AC로 바꾸는 2중 변환을 사용하여 Double Conversion 방식이라 불리기도 합니다.<br /><br />
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs lg='3'>
                            <Card border="info">
                                <Card.Link href='/contact' target='_blank' rel="noopener noreferrer">
                                    <Card.Img variant="top" src={ups2} width='60%' />
                                </Card.Link>
                                <Card.Body>
                                    {/*<Card.Link href='/contact' target='_blank'><Card.Img variant="top" src={line} width='10%' /></Card.Link>*/}
                                    <Card.Title><b>LINE-interactive</b></Card.Title>
                                    <Card.Text>{/*
                                        병렬보상방식(LINE-INTERACTIVE) 은 OFF LINE과 ON LINE방식 장점만 취한 장비이며 평상시에는 입력전원을 출력전원으로 공급하지만 전압변동이 일정한 범위를 벗어나면 고조파 및 서지를 억제하여 출력전압 품질을 올립니다.
                                        충전은 장비사용시 자동으로 충전부를 통하여 충전합니다.*/}
                                        입력되는 전원이 정상적인 경우에 출력전압을 일정하게 유지하도록 자동 전압 조정 기능을 내장한 방식으로 ON-LINE과 OFF-LINE 방식의 중간 정도의 기술이며 장단점을 보완한 것 입니다.
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                        <Col xs lg='3'>
                            <Card border="info">
                                <Card.Link href='/contact' target='_blank' rel="noopener noreferrer">
                                    <Card.Img variant="top" src={ups3} width='60%' />
                                </Card.Link>
                                <Card.Body>
                                    <Card.Title><b>OFF-LINE</b></Card.Title>
                                    <Card.Text>
                                        {/*정상운전시 입력전원은 STATIC SWITCH를 통해 출력합니다.
                                        이때 정류부(AC/DC CHARGER)를 통해 배터리를 충전합니다.
                                        입력전원이 스위치츨 통해 바로 출력으로 나가기 때문에 입력전원이 변동될 때 출력전원도 마찬가지로 변동합니다.
                                    그래서 전원이 불안정하다면 OFF-LINE방식의 사용은 적합하지 않을 수 있습니다.*/}
                                        정상시 교류입력전원을 사용하다가 정전되거나 입력전원이 허용치보다 낮을 경우에 인버터(UPS)를 사용하는 방식입니다.<br /><br />
                                    </Card.Text>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </div>
            </div>

        </>
    );
};

export default Electrical;