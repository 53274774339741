import React from 'react';
import {
  MDBCarousel,
  MDBCarouselItem,
} from 'mdb-react-ui-kit';
import "../../static/css/default.css"
import dc from '../../static/img/slides/dc_slide.png'
import cloud from '../../static/img/slides/cloud_slide.png'
import Card from 'react-bootstrap/Card';

/*const data = [
  {
    id: 1,
    img:
      "https://mdbootstrap.com/img/new/slides/041.jpg",
    targetURL: "http://172.17.0.122:3000/datacenter"
  },
  {
    id: 2,
    img:
      "https://mdbootstrap.com/img/new/slides/042.jpg",
    targetURL: "http://172.17.0.122:3000/msp"
  },
  {
    id: 3,
    img:
      "https://mdbootstrap.com/img/new/slides/043.jpg",
    targetURL: "http://172.17.0.122:3000/greetings"
  },
];
*/
export default function Slide() {
  return (
    <MDBCarousel showControls interval={5000}>
      <Card.Link href='/datacenter/hvac' target='_blank'>
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={1}
        src={dc}
        alt='...'
      >
        <h5 className='context-slide'>Datacenter Solution</h5>
        
      </MDBCarouselItem>
      </Card.Link>
      <Card.Link href='/cloud/kt' target='_blank'>
      <MDBCarouselItem
        className='w-100 d-block'
        itemId={2}
        src={cloud}
        alt='...'
      >
        <div className='context-slide'>Cloud Solution</div>
      </MDBCarouselItem>
      </Card.Link>
      {/*<MDBCarouselItem
        className='w-100 d-block'
        itemId={3}
        src={is}
        alt='...'
      >
        <h5 className='context-slide'>Security Solution</h5>
  </MDBCarouselItem>*/}
    </MDBCarousel>
  );
}