import React from 'react';
import "../../../static/css/default.css"
import "../../../static/css/greetings.css"
import ContentsHeader from '../../Layout/Contents_Header'

const Greetings = () => {
    return (
        <>
        <ContentsHeader name='company'/>
        <div className='greetings'>
            <br/>
            <h3><b>인사말</b></h3><br />
            <h5><b>"IDC/클라우드 및 보안서비스의 확실한 길잡이가 되어드리겠습니다."</b></h5>
            <div className='solid' style={{marginTop: "1.5%", marginBottom: "1.5%"}}></div>
            <div className='greetings_contents' style={{color: 'black'}}>
            주호시스템즈는 2015년 창립 이래로<br /><br />
            데이터센터 설비부터 서버 / 클라우드 서비스를 포괄하는<br />
            모든 레이어의 컨설팅, 운영, 관제 등 토탈서비스 전문 기업으로<br /><br />
            신속하고 적극적인 자세로 고객의 요청에 즉각적으로 대응하며<br />
            안전과 신뢰를 제공함으로써<br /><br />
            고객의 가치를 보호하고 사업의 연속성과 발전에 이바지 하고자 합니다.<br />
            <br /><br />
            <b>㈜주호시스템즈</b> 임직원 일동
            </div>
        </div>
        </>
    );
};

export default Greetings;