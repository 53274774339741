/* eslint-disable no-restricted-globals */
import React, { Component } from 'react';
import { CDBFooter, CDBFooterLink, CDBBox } from 'cdbreact';
import Logo from '../../static/img/logo.png'
import "../../static/css/default.css"
import $ from 'jquery';
import Button from 'react-bootstrap/Button';
import Family from './footerSite';

class Footer extends Component {
  componentDidMount() {
    $(document).ready(function () {
      var sw = true;

      $('.family button').click(function () {
        sw = !sw; //부정연산자(not)을 변수값 앞에 붙여서 클릭할때마다 true, false가 되게함.

        if (sw === true) {
          $('.family .family_list').hide();
        } else {
          $('.family .family_list').show();
        }
      });
    });
  };
  render() {

    return (
      <>
        <CDBFooter style={{ textDecoration: 'none', borderTop: 'solid #E2E2E2 1px', borderBottom: 'solid #E2E2E2 1px' }}>
          <CDBBox display="flex" flex="column" className="mx-auto py-5" style={{ width: '70%' }}>
            <CDBBox display="flex" justifyContent="between" className="flex-wrap">
              <CDBBox>
                <p className="h5 mb-2">
                  <b>정책</b>
                </p>
                <CDBBox flex="column" display='flex' style={{ cursor: 'pointer', padding: '0' }}>
                  <CDBFooterLink href="/terms/privacy" >개인정보 처리방침</CDBFooterLink>
                  {/*<CDBFooterLink href="/terms/web">웹 이용약관</CDBFooterLink>*/}
                </CDBBox>
              </CDBBox>
              <CDBBox>
                <p className="h5 mb-2 footer_content">
                  <b>회사소개</b>
                </p>
                <CDBBox flex="column" display='flex' style={{ cursor: 'pointer', padding: '0' }}>
                  <CDBFooterLink href="/greetings" >인사말</CDBFooterLink>
                  <CDBFooterLink href="/years">연혁</CDBFooterLink>
                  <CDBFooterLink href="/map">오시는 길</CDBFooterLink>
                </CDBBox>
              </CDBBox>
              <CDBBox>
                <p className="h5 mb-2 footer_content">
                  <b>데이터센터</b>
                </p>
                <CDBBox flex="column" display='flex' style={{ cursor: 'pointer', padding: '0' }}>
                  <CDBFooterLink href="/datacenter/hvac">공조</CDBFooterLink>
                  <CDBFooterLink href="/datacenter/electrical">전기</CDBFooterLink>
                  <CDBFooterLink href="/datacenter/equipment">설비</CDBFooterLink>

                </CDBBox>
              </CDBBox>
              <CDBBox>
                <p className="h5 mb-2 footer_content">
                  <b>클라우드</b>
                </p>
                <CDBBox flex="column" display='flex' style={{ cursor: 'pointer', padding: '0' }}>
                  <CDBFooterLink href="/cloud/kt">KT Cloud</CDBFooterLink>
                  <CDBFooterLink href="/cloud/aws">AWS</CDBFooterLink>
                </CDBBox>
              </CDBBox>
              <CDBBox>
                <p className="h5 mb-2 footer_content">
                  <b>고객센터</b>
                </p>
                <CDBBox flex="column" display='flex' style={{ cursor: 'pointer', padding: '0' }}>
                  <CDBFooterLink href="/contact">문의</CDBFooterLink>
                </CDBBox>
              </CDBBox>
            </CDBBox>

          </CDBBox>
        </CDBFooter>
        <CDBFooter>
          <CDBBox
            display="flex"
            justifyContent="between"
            alignItems="center"
            className="mx-auto py-4 flex-wrap"
            style={{ width: '80%' }}
          >
          </CDBBox>
          <CDBBox display="flex" alignItems="center" className='contact_box'>
            <img alt="logo" src={Logo} width="200px" style={{ marginLeft: '3%', marginTop: '0.5%', marginBottom: '0.5%' }} />
            <small className="ml-5" style={{ fontSize: 13, fontFamily: 'Jalnan', marginLeft: '5%' }}>
              <b>주소: 서울 강남구 테헤란로 116 동경빌딩 10층 / 대표자: 이경렬 / 전화: 1577-6077 / 이메일: 기술(tech@vikcloud.net) | 영업(consulting@vikcloud.net) | 요금(admin@vikcloud.net)<br />
                개인정보보호책임자: 이경렬(audit@vikcloud.net) / 사업자등록번호: 754-88-00027 / 통신판매업 신고번호: 제 2021-서울강남-05898호</b>
            </small>
            <Button variant="primary" href='/contact' style={{ marginLeft: '20%' }}>문의</Button>
          </CDBBox>
        </CDBFooter>
        {/*<Family />*/}
        {/*        <div class="family">
         <button type="button">Releated Site</button>
            <ul class="family_list">
             <li>Releated Site</li>
                <li><a href="https://cloud.kt.com" rel="noopener" >KT Cloud</a></li>
                <li><a href="https://aws.amazon.com/ko/" rel="noopener">AWS Cloud</a></li>
                <li><a href="https://www.panduit.com/en/home.html" rel="noopener">Panduit</a></li>
                <li><a href="https://vikcloud.co.kr" rel="noopener">Vikcloud</a></li>
            </ul>
      </div>*/}
      </>
    )
  }
};

export default Footer;