import React, {useEffect} from 'react';
import "../../../static/css/default.css"
import ContentsHeader from '../../Layout/Contents_Header';
const { kakao } = window;

const Map = () => {
    useEffect(()=>{
        
        var container = document.getElementById('map');
        
        var options = {
          center: new kakao.maps.LatLng(37.49856458528519, 127.030567539157),
          level: 3
        };
    
        var map = new kakao.maps.Map(container, options);
        var markerPosition  = new kakao.maps.LatLng(37.49856458528519, 127.030567539157); 
        var marker = new kakao.maps.Marker({
        map: map,
          position: markerPosition
          
      });
      var infowindow = new kakao.maps.InfoWindow({
        content: '<div class="map_addr"><b>서울시 강남구 테헤란로 116<br>동경빌딩 10층</b></div>'
      });
      infowindow.open(map, marker);
      marker.setMap(map);
/*
      var map = new Tmapv3.Map("map", { // 지도가 생성될 div
        center : new Tmapv3.LatLng(37.56520450, 126.98702028),
        width : "60%",	// 지도의 넓이
        height : "100%",	// 지도의 높이
        zoom : 15	// 지도 줌레벨
    });*/
    }, [])    
    return (
        <>
        <ContentsHeader name='company'/>
        <div className='context'>
        <div className='context_map'>

            <h2><b>오시는 길</b></h2>
            <br/>
            <br/>
            <b>지하철</b><br/>
            2호선 / 신분당선 강남역 1번 출구 도보 3분<br/><br/>
            <b>버스 정류장</b><br/>
            강남역12번출구 23-284<br/>
            강남역12번출구 (공항)<br/>
            강남역.역삼세무서 23-287<br/>
            강남역 (공항)<br/><br />
            <b>주차</b><br/>
            동경빌딩 지하 1층<br/><br/>
            
        </div>
            <div id='map' style={{
                width: '55vw',
                height: '55vh',
                border: 'sky 1px solid',
                marginBottom: '0%'
            }} className='map'/> 
            </div>
        </>
    );
};

export default Map;
