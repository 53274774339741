import { menuItems } from './lib/menuItems';
import MenuItem from './lib/MenuItem';
const Navbar = () => {
    
    return (
        <nav>
        <ul className="menus">
            <br/>
          {menuItems.map((menu, index) => {
            const depthLevel = 0;
            return <MenuItem items={menu} key={index} depthLevel={depthLevel}/>;
          })}
        </ul>
      </nav>
    );
  };
  
  export default Navbar;