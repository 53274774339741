import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Logo from '../../static/img/logo.png'
import { DropdownSubmenu } from "react-bootstrap-submenu";
import { useState } from 'react'
import $ from 'jquery'

function Header(props) {
  $('.dropdown').hover(function(){ 
    $('.dropdown-toggle', this).trigger('click'); 
 });
  const [show, setShow] = useState(false);
  const [show2, setShow2] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  }
  const hideDropdown = e => {
    setShow(false);
  }
  const showDropdown2 = (e) => {
    setShow2(!show2);
  }
  const hideDropdown2 = e => {
    setShow2(false);
  }
  return (
    <>
      <Navbar collapseOnSelect expand="lg" style={{ marginRight: '5%' }}>
        <Navbar.Brand href="/">
          <img
            src={Logo}
            width="200"
            height="67"
            className="d-inline-block align-top"
            alt="Vikcloud + Juhosystems Log"
            style={{ marginLeft: '20%' }}
          />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className='navbar-nav ms-auto mb-2 mb-lg-0 mr-auto ml-auto'>
            <NavDropdown title={<b><span className="black">회사소개</span></b>} id="basic-nav-dropdown" style={{ fontFamily: 'Jalnan' }} alignRight
              show={show}
              onMouseEnter={showDropdown}
              onMouseLeave={hideDropdown}
            >
              <NavDropdown.Item href="/greetings"><span className="black">인사말</span></NavDropdown.Item>
              <NavDropdown.Item href="/years"><span className="black">연혁</span></NavDropdown.Item>
              <NavDropdown.Item href="/map"><span className="black">오시는 길</span></NavDropdown.Item>
            </NavDropdown>
            &nbsp;&nbsp;&nbsp;
            <NavDropdown title={<b><span className="black">사업영역</span></b>} id="basic-nav-dropdown" style={{ fontFamily: 'Jalnan' }}  className='dropdown-menu-right'
              show={show2}
              onMouseEnter={showDropdown2}
              onMouseLeave={hideDropdown2}
            >
              <DropdownSubmenu href="/datacenter" title={<span className="black">데이터센터</span>} onToggle='select'>
                <NavDropdown.Item href="/datacenter/hvac">공조</NavDropdown.Item>
                <NavDropdown.Item href="/datacenter/electrical">전기</NavDropdown.Item>
                <NavDropdown.Item href="/datacenter/equipment">설비</NavDropdown.Item>
              </DropdownSubmenu>
              <DropdownSubmenu href="/cloud" title={<span className="black">클라우드</span>} onToggle='select'>
                <NavDropdown.Item href="/cloud/kt">KT Cloud</NavDropdown.Item>
                <NavDropdown.Item href="/cloud/aws">AWS</NavDropdown.Item>
              </DropdownSubmenu>
            </NavDropdown>
            &nbsp;&nbsp;&nbsp;
            <Nav.Link href="/contact"><span className="black"><b>문의</b></span></Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </>
  );
}

export default Header;