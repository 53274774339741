import { Component } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { 
  Home, Greetings, Years, 
  Map, Contact, 
  NotFound, Privacy,
  Ktcloud, Aws, Hvac, Electrical,
  Equipment
} from './Components/Pages';

class App extends Component {
  render() {
    return (
          <BrowserRouter>
            <div id = "root">
              <Routes>
                <Route exact path="/" element={<Home />} />
                <Route path="/greetings" element={<Greetings />} />
                <Route path="/years" element={<Years />} />
                <Route path="/map" element={<Map />} />

                <Route path="/datacenter/hvac" element={<Hvac />} />
                <Route path="/datacenter/electrical" element={<Electrical />} />
                <Route path="/datacenter/equipment" element={<Equipment />} />
                

                <Route path="/cloud/kt" element={<Ktcloud />}/>
                <Route path="/cloud/aws" element={<Aws />}/>

                <Route path="/contact" element={<Contact />}/>

                <Route path="/terms/privacy" element={<Privacy />}/>

                <Route path="*" element={<NotFound />}/>
              </Routes>
            </div>  
          </BrowserRouter>
    );
  }
}

export default App;
