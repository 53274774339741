export const menuItems = [
    {
        title: '회사소개',
        url: '#',
        submenu: [
            {
                title: '인사말',
                url: '/greetings'
            },
            {
                title: '연혁',
                url: '/years'
            },
            {
                title: '오시는 길',
                url: '/map'
            },
        ]
    },
    {
        title: '사업영역',
        url: '#',
        submenu: [
            {
                title: '클라우드',
                url: '#',
                submenu: [
                    {
                        title: 'KT Cloud',
                        url: '/cloud/kt'
                    },
                    {
                        title: 'AWS',
                        url: '/cloud/aws'
                    },
                ]
            },
            {
                title: '데이터센터',
                url: '#',
                submenu: [
                    {
                        title: '공조',
                        url: '/datacenter/hvac'
                    },
                    {
                        title: '전기',
                        url: '/datacenter/electrical'
                    },
                    {
                        title: '설비',
                        url: '/datacenter/equipment'
                    },
                ]
            },
        ]
    },
    {
        title: '문의',
        url: '/contact'
    }
]